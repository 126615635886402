<template>
  <div>
    <AppHeader v-if="!isMobile">
      <template v-slot:left-action>
        <arrow-left-icon class="arrow-left" @click.prevent="goToDashboard" />
      </template>

      <template v-slot:title>
        <h2>{{ $t("packetSearch.packets") }}</h2>
        <h1>{{ $t("packetSearch.receive-release") }}</h1>
      </template>
    </AppHeader>

    <main>
      <form class="container" @submit.prevent>
        <label for="searchInput" class="search-input-label">
          <span v-if="featureFlags.frontend_packet_search_label_slash">
            {{ $t("packetSearch.passwordAlternative") }}
          </span>
          <span v-else>{{ $t("packetSearch.password") }}</span>
          <span class="mandatory">*</span>
        </label>
        <div class="search-input-wrapper">
          <input
            type="text"
            id="searchInput"
            :value="searchCode"
            @input="handleInput"
            ref="searchForm"
            autocorrect="off"
            autocapitalize="off"
            autocomplete="off"
            spellcheck="false"
            :disabled="searching"
          />
          <button
            v-if="searchCode"
            type="button"
            :class="isMobile && 'shift-left'"
            @click="clearSearchInput"
          >
            <close-icon :size="20" class="flat-icon close-icon" />
          </button>
          <a
            href="#"
            v-if="isMobile && !isPaymentDevice"
            @click.prevent="triggerMobileOCR"
          >
            <camera-icon :size="30" class="flat-icon camera-icon" />
          </a>
        </div>
      </form>
      <div class="text-center" v-if="showSuccess">
        <check-icon :size="160" />
      </div>
      <Errors :errors="errors" :errorMedium="true" />
    </main>
    <Spinner v-if="loading" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { SEARCH_TYPES } from "@/store/packet.module";
import { RECEIVE_PACKET_TYPES } from "@/store/packet.module";
import { RETURN_SHIPMENT_TYPES } from "@/store/packet.module";
import _ from "lodash";
import { MOBILE_TYPES } from "@/util/mobile.type";
import Errors from "@/components/Errors";
import AppHeader from "@/components/AppHeader";
import ArrowLeftIcon from "vue-material-design-icons/ArrowLeft.vue";
import CameraIcon from "vue-material-design-icons/Camera.vue";
import CheckIcon from "vue-material-design-icons/Check.vue";
import CloseIcon from "vue-material-design-icons/Close.vue";
import { getParams } from "@/mixins/platform-params.js";
import Spinner from "@/components/Spinner";

const SEARCH_INPUT_MAX_LENGTH = 12;

export default {
  name: "PacketSearch",
  mixins: [getParams],
  components: {
    Errors,
    AppHeader,
    ArrowLeftIcon,
    CameraIcon,
    CheckIcon,
    CloseIcon,
    Spinner
  },
  data() {
    return {
      searching: false,
      showSuccess: false,
      SEARCH_TYPES: SEARCH_TYPES
    };
  },

  beforeRouteLeave(to, from, next) {
    this.clearErrors();
    next();
  },

  computed: {
    ...mapState(["errors", "loading", "featureFlags"]),

    searchCode: {
      get: function() {
        return this.$store.state.packet.searchCode;
      },

      set: function(searchCode) {
        return this.setSearchCode(searchCode);
      }
    }
  },

  watch: {
    searchCode() {
      this.callDebounce();
    }
  },

  created() {
    this.callDebounce = _.debounce(this.onType, 1000);
  },

  mounted() {
    _.delay(() => {
      this.$refs.searchForm.focus();
    }, 500);
  },

  methods: {
    ...mapActions("packet", [
      "createSearchType",
      "getPacketDetail",
      "getReturnPackets",
      "getReturnShipment",
      "receiveC2CPacket",
      "deliverPacket"
    ]),

    ...mapMutations(["setErrors", "clearErrors"]),
    ...mapMutations("packet", ["setSearchCode"]),

    goToDashboard() {
      this.$router.push({
        name: "dashboard",
        query: {
          platform: this.platform,
          device: this.device
        }
      });
    },

    delayRoutingToPath(path) {
      this.showSuccess = true;

      _.delay(() => {
        this.showSuccess = false;
        this.$router.push({
          name: path,
          params: { code: this.searchCode },
          query: {
            platform: this.platform,
            device: this.device
          }
        });
      }, 1000);
    },

    sendBarcodeToMobile(barcode) {
      if (this.isMobile) {
        if (this.platform == MOBILE_TYPES.ANDROID) {
          window.PacketaPPA.sendBarcode(barcode);
        } else if (this.platform == MOBILE_TYPES.IOS) {
          window.webkit.messageHandlers.sendBarcode.postMessage({
            barcode: barcode
          });
        }
      }
    },

    handleInput({ target }) {
      // Remove all whitespaces
      let value = target.value.replace(/\s/g, "");
      const currentLength = value.length;

      if (currentLength > SEARCH_INPUT_MAX_LENGTH) {
        value = value.slice(0, SEARCH_INPUT_MAX_LENGTH);
      }

      // Update the value inside the input and the state
      target.value = this.searchCode = value;
    },

    clearSearchInput() {
      this.searchCode = "";
      this.$refs.searchForm.focus();
    },

    toggleSearchInput(force) {
      this.searching = !force;
      if (!force) return;

      this.$nextTick(() => {
        this.$refs.searchForm.focus();
      });
    },

    triggerMobileOCR() {
      if (this.platform == MOBILE_TYPES.ANDROID) {
        window.PacketaPPA?.openOCR?.();
      }

      this.$refs.searchForm.focus();
    },

    onType() {
      if (this.searchCode && this.searchCode.length >= 5) {
        this.clearErrors();
        this.toggleSearchInput(false);

        this.createSearchType(this.searchCode)
          .then(searchType => {
            if (searchType) {
              switch (searchType) {
                case SEARCH_TYPES.DELIVERY_PASSWORD: {
                  this.getPacketDetail(this.searchCode).then(packet => {
                    if (packet) {
                      if (this.isMobile) {
                        this.sendBarcodeToMobile(packet.barcode);
                        this.delayRoutingToPath("packet-check");
                      } else {
                        if (packet.adultContent) {
                          this.delayRoutingToPath("packet-age-check");
                        } else {
                          this.deliverPacket({
                            password: this.searchCode,
                            barcode: packet.barcode,
                            deliveryType: "password"
                          }).then(resp => {
                            if (resp) {
                              this.delayRoutingToPath("packet-detail");
                            }
                          });
                        }
                      }
                    }
                  });
                  break;
                }
                case SEARCH_TYPES.RETURN_PASSWORD:
                case SEARCH_TYPES.C2C_RETURN_PASSWORD:
                case SEARCH_TYPES.B2C_RETURN_PASSWORD: {
                  this.getReturnPackets(this.searchCode).then(sender => {
                    if (sender) {
                      if (this.isMobile) {
                        if (this.platform == MOBILE_TYPES.ANDROID) {
                          window.PacketaPPA.sendSender(sender.name);
                        } else if (this.platform == MOBILE_TYPES.IOS) {
                          window.webkit.messageHandlers.sendSender.postMessage({
                            sender: sender.name
                          });
                        }
                      }
                      this.delayRoutingToPath("packets-return");
                    }
                  });
                  break;
                }
                case SEARCH_TYPES.CLAIM_FROM_CUSTOMER_PASSWORD:
                case SEARCH_TYPES.B2C_CONSIGN_PASSWORD:
                case SEARCH_TYPES.C2C_CONSIGN_PASSWORD: {
                  this.receiveC2CPacket({
                    code: this.searchCode,
                    type:
                      searchType == SEARCH_TYPES.C2C_CONSIGN_PASSWORD
                        ? RECEIVE_PACKET_TYPES.C2C
                        : searchType == SEARCH_TYPES.B2C_CONSIGN_PASSWORD
                        ? RECEIVE_PACKET_TYPES.B2C
                        : RECEIVE_PACKET_TYPES.CLAIM_FROM_CUSTOMER
                  }).then(barcode => {
                    if (barcode) {
                      this.delayRoutingToPath("packet-receive");
                    }
                  });
                  break;
                }
                case SEARCH_TYPES.SURPRISE_CODE:
                case SEARCH_TYPES.SURPRISE_CONSIGN_PASSWORD: {
                  let returnShipmentType =
                    searchType == SEARCH_TYPES.SURPRISE_CODE
                      ? RETURN_SHIPMENT_TYPES.ESHOP_CODE
                      : RETURN_SHIPMENT_TYPES.PASSWORD;
                  this.getReturnShipment({
                    code: this.searchCode,
                    returnShipmentType: returnShipmentType
                  }).then(eshopName => {
                    if (eshopName) {
                      this.delayRoutingToPath("packet-return-shipment");
                    }
                  });
                  break;
                }
                default:
                  this.setErrors([
                    {
                      code: searchType,
                      text: this.$t("packetSearch.unsupported_code_error")
                    }
                  ]);
              }
            }
          })
          .finally(() => this.toggleSearchInput(true));
      } else {
        /* Clear errors if search code is too short */
        this.clearErrors();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
main {
  padding: 0 40px;

  .check-icon {
    color: $green;
  }
}

.search-input {
  &-label {
    display: inline-block;
    margin: 23px 0 0;
  }

  &-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    input {
      padding: 12px 9px;
      font-size: 1.375rem !important;
      line-height: 1;
      text-transform: uppercase;
    }

    button,
    a {
      position: absolute;
      inset: 0 0 0 auto;
      display: flex;
      padding-inline: 0.75em;
      align-items: center;

      .flat-icon {
        line-height: 0;
      }

      .close-icon {
        opacity: 0.4;
      }

      .camera-icon {
        color: $rust-red;
      }
    }

    button {
      background: none;
      border: 0;

      &.shift-left {
        right: 3rem;
      }

      &:hover {
        cursor: pointer;

        .close-icon {
          opacity: 0.6;
        }
      }
    }
  }
}
</style>
